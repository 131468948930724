import { FC } from "react";
import { Link } from "react-router-dom";
import { data } from "../data";
import { Suggestion } from "./suggestion";
import { SuggestionSmall } from './suggestion-small';

export function SuggestionContainer() {
	interface Imountain {
		Name: string;
		Height: number;
		Latitude: number;
		Longitude: number;
		Book: string;
		ID: number;
	}

	let selectedFell: Imountain = data[getRandomInt(data)];

	const numberOfSuggestions = 5;

	function getRandomInt(data: any): number {
		return Math.floor(Math.random() * data.length);
	}

	function getRandomNumber(data: any): number {
		return Math.floor(Math.random() * data) + 1;
	}

	return (
		<>
		<h1 className='px-8 text-3xl max-w-sm text-gray-900 w-5/6 text-left poppinsM mb-0'>Wainwrights for you</h1>
		<p className='px-8 poppinsM text-sm text-left text-gray-500'>We've found 4 Wainwrights you're yet to Ascend.</p>

			<div className=''>
				<div id='scrollContainer' className='flex h-full w-screen flex-row flex-wrap'>
					<SuggestionSmall />
					<SuggestionSmall />
					<SuggestionSmall />
					<SuggestionSmall />
				</div>
			</div>
		</>
	);
}
