import { Link } from "react-router-dom";
import { data } from "../data";

export function SuggestionSmall() {
	interface Imountain {
		Name: string;
		Height: number;
		Latitude: number;
		Longitude: number;
		Book: string;
		ID: number;
	}

	let selectedFell: Imountain = data[getRandomInt(data)];

	function getRandomInt(data: any): number {
		return Math.floor(Math.random() * data.length);
	}

  function getRandomNumber(data: any): number {
		return Math.floor(Math.random() * data) + 1;
	}

  function trimBook(title: string): string | null {
    const inputString = title;
    const match = inputString.match(/-(.*)/);

    if (match) {
      const result = match[1].trim();
      console.log(result);
    }
    return match ? match[1].trim() : 'Lake District'
  }

	return (
        <div className='w-full m-2 rounded-md overflow-hidden'>
          <div className="overflow-hidden flex flex-col h-60 relative">
            <img className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2' src={`/assets/images/mount-${getRandomNumber(5)}.jpg`} />
            <div className='p-4 transparentMainBackGreen flex align-middle justify-center flex-col absolute w-full h-full min-h-full'>
              <h1 className='text-left text-white text-3xl poppinsB capitalize'>{selectedFell.Name}</h1>
              <p className='text-left text-white text-m pb-0 mb-0'>{trimBook(selectedFell.Book)}</p>
              <p className='text-left text-gray-50 text-xs'>{selectedFell.Height}m</p>
              <Link className='no-underline' role='button' to={`/map/${selectedFell.ID}/true`}>
                <div className='secondaryColour text-white px-3 py-1 rounded-md capitalize no-underline align-bottom w-fit poppinsM'>Ascend</div>
              </Link>
            </div>
          </div>
        </div>
  );
}
