import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { auth, db } from './init-firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth"
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';


export function GoogleLogin(): JSX.Element {
  const provider = new GoogleAuthProvider();

  const handleClick = async () => {
    await signInWithPopup(auth, provider)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential!.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);

            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 0) {
              await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                count: 0
              });
              console.log('made new doc')
            } else {
              console.log('user exists')
            }

      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        console.error(error);
      });
  }

  return (
    <button onClick={handleClick}>
      Login with Google
    </button>
  );
}
