import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from '../firebase/auth-provide';
import { Link } from 'react-router-dom';
import { data } from "../data";

function WelcomeIntro() {
  const userData = useDataContext();
	const geolocation = useRef(navigator.geolocation);
	const permissions = useRef(navigator.permissions);
  const [coordinates, setCoordinates] = useState<{
		latitude: number;
		longitude: number;
	} | null>(null);
  const [closeBy, setCloseBy] = useState<number>(0);

  useEffect(() => {
    window.console.log('looking for permissions')
		if (permissions.current && permissions.current.query) {
			permissions.current.query({ name: "geolocation" }).then(function (result) {
				const permission = result.state;
        window.console.log('current permissions are ', permission)
				if (permission === "granted" || permission === "prompt") {
					geolocation.current.getCurrentPosition((position) => {
						setCoordinates({
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						});
					});
				}
			});
		}
	}, [permissions, geolocation, setCoordinates]);

  useEffect(() => {
    window.console.log('TESTING WITHIN RANGE')
    let withinRange = 0;

    data.forEach(fell => {
        if(coordinates && isWithin5Kilometers(fell.Latitude,fell.Longitude, coordinates.latitude, coordinates.longitude)){
          withinRange++
          window.console.log('found one close by', fell.Name)
        }
    });
    window.console.log('THERE ARE ', withinRange, ' FELLS WITHIN 5KM')
    setCloseBy(withinRange)
  }, [coordinates, permissions, geolocation, setCoordinates])

  function getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    window.console.log('get distance')
    const earthRadius = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in kilometers
  
    return distance;
  }
  
  function isWithin5Kilometers(lat1: number, lon1: number, lat2: number, lon2: number) {
    const distance = getDistance(lat1, lon1, lat2, lon2);
    window.console.log(distance)
    return distance <= 5;
  }

  useEffect(() => {
    if (userData.user) {
      window.console.log('welcome!', userData.user)
    }
  }, [userData]);

  return (

      <div className='w-full h-3/4'>
        <div className="overflow-hidden flex flex-col h-100 relative">
          {/* <img className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 object-cover min-h-full min-w-full' src={`/assets/images/mount-${getRandomNumber(5)}.jpg`} /> */}
          <div className='p-4 mainBackGreen flex align-middle justify-center flex-col absolute w-full h-full min-h-full '>
            <h1 className='text-left w-2/3 text-white text-7xl poppinsB'>Where to next?</h1>
            <p className='text-left text-white text-m poppinsM mb-5'>{closeBy > 0 ? `There are ${closeBy} Wainwirghts nearby.` : `Find your next adventure.`}</p>
            <Link className='no-underline' role='button' to={closeBy > 0 ? '/map/0/false' : `/map/1/true`}>
              <div className='secondaryColour text-white px-3 py-1 rounded-md capitalize no-underline align-bottom w-fit poppinsM'>View Map</div>
            </Link>
          </div>
        </div>
      </div>
  )
}

export default WelcomeIntro