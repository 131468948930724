import React, { ReactNode, useState } from 'react'
import { useDataContext } from '../../firebase/auth-provide';
import ListChild from './list-child';
import { data as masterData } from "../../data";


function ListComponent() {
  const userData = useDataContext();
  const fellData = userData.userFellData
  const [filterOptions, setFilterOptions] = useState<any>(
    {
      showAll: true,
      showComplete: true,
      showIncomplete: true,
    }
  );
  let buttonText = 'Show Completed';
 
  function getlistItems() {
    if(!fellData){
      return;
    }
    const objectList = [];
    for (const fell of masterData) {
      objectList.push(
        <ListChild key={fell.ID} data={fell} filter={filterOptions} />
      );
    }
    return (
    <ul role="list" className="divide-y divide-gray-100 p-3">

    {/* <ul className='p-1 mb-20'> */}
      {objectList}</ul>
    );
  }

  function handleButtonClick(){
    const toggleCurrentOption = !filterOptions.showIncomplete;
    // window.console.log('button clicked')
    setFilterOptions({
      showAll: true,
      showComplete: toggleCurrentOption,
      showIncomplete: toggleCurrentOption,
    })
    buttonText = filterOptions.showComplete ? 'Show All' : 'Show Completed'
    window.console.log(filterOptions, buttonText)
  }

  function getbuttonText() {
    return filterOptions.showComplete ? 'Show All' : 'Show Completed'
  }


  return (
    <>    
      <button type='button' onClick={handleButtonClick}>{getbuttonText()}</button>
      {getlistItems()}
    </>
  )
}

export default ListComponent