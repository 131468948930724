import React from 'react';
import { useParams } from 'react-router-dom';
import { data } from '../data';

export function Map(): JSX.Element {
  const {id} = useParams()


  interface Imountain {
    Name: string;
    Height: number;
    Latitude: number;
    Longitude: number;
    Book: string;
  }

  let mountdata = data.find((mount) => mount.Name === id)
  
  return (
    <>
 
        {mountdata ? (
        <div>
        <h1>{id}</h1>
        <p>This is an example of a mountain page. {id} </p>
        <p>
          {mountdata.Height}
          </p>
          <p>
          {mountdata.Book}
          </p>

          <p>
          #004225
          #E4D6A7
          #E9B44C
          #9B2915
          #50A2A7
          A Wainwright Fell is a mountain or hill in the Lake District region of northwest England. The term "Wainwright" refers to Alfred Wainwright, a British fellwalker and guidebook author who is known for his seven-volume guidebook series called "A Pictorial Guide to the Lakeland Fells." These guidebooks detail the fells of the Lake District and have helped to make the region one of the most popular destinations for hikers in the United Kingdom. Wainwright Fells are known for their rugged beauty and challenging hikes, and they are a must-see destination for anyone visiting the Lake District.
          </p>
       
        
  
        </div>
         ) : (
        <p>sorry no data available</p>
  )}
    </>
   
    
  );
};

export default Map;