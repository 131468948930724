import React, { useEffect } from 'react';
import ListComponent from '../components/list-component/list-master';
import { useDataContext } from '../firebase/auth-provide';
import Profileheader from '../components/profile-header';
import PageHeader from '../components/page-header';

const Profile = () => {

  const user = useDataContext();

  window.console.log('what is ....', user)
  // useEffect(() => {
  //   console.log(user)
  // }, [])
  console.log('is user', user)
  
  return (
    <div>
      <PageHeader title={'Profile'}></PageHeader>
      <div>
        <Profileheader></Profileheader>
        <ListComponent></ListComponent>
    </div>
    </div>
  );
};

export default Profile;