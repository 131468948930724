import React, { useContext, useEffect } from 'react';
import { MyMap } from '../components/map-component'

import { useState } from 'react';
import { Container } from 'react-bootstrap';
import MapPanel from '../components/map-panel';
import { useDataContext, UserDataContext } from '../firebase/auth-provide';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/init-firebase';
import { addFellItem } from '../firestore/send-data';

export function Map(): JSX.Element {
  const userData = useDataContext();
  const fellData = userData.userFellData

const { user, setUser, userFellData, setUserFellData } = useContext(UserDataContext);


  const [panelVisible, setPanelVisible] = useState<boolean>(false);
  const [activeFell, setactiveFell] = useState<any>(null);

  const [userFellCollection, setUserFellCollection] = useState<any>(null)

  useEffect(() => {
    if (userData.user) {
      setUserFellCollection("users/" + userData.user.uid + "/completed")
    }
  }, [userData]);

  async function addClimbData() {
    if(!userData.user){
      window.console.log('no user cant add...')
      return
    }
    window.console.log('add data', activeFell.ID, userFellCollection, userData.user)
    await addFellItem(userFellCollection, activeFell.ID)
    .then((dataadded) => {
      setUserFellData(Object.assign(dataadded, userFellData))
    })
    .catch((e) => {
      console.log('there was an error with sending the data', e)
    })
  }

  function mapIconSelected(userSelected: boolean, data?:any,){
    if(data){
      setactiveFell(data)
    }
    window.console.log()
    window.console.log(userSelected);
    setPanelVisible(userSelected)
  }

  return (
    <div className="overflow-hidden h-9/10 relative">
      <MyMap parentFunction={mapIconSelected} testdata={{ userFells: userData }} />
      <MapPanel parentFunction={addClimbData} data={{ fell: activeFell, open: panelVisible }}/>
    </div>
  );
};