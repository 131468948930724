import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from '../firebase/auth-provide';

function Welcomeheader() {
  const userData = useDataContext();

  useEffect(() => {
    if (userData.user) {
      window.console.log('welcome!', userData.user)
    }
  }, [userData]);

  const getUserImage = () => {

    if(userData.user && userData.user.photoURL){
      return userData.user.photoURL
    } else {
      return '/assets/images/mount-1.jpg'
    }
  }

  window.console.log(userData)
  return (
    <div className='bg-white p-3'>
      <div className='flex flex-row md:flex-col justify-center'>
        <img className='w-12 h-12 rounded-full' src={getUserImage()} alt="Profile picture"></img>
        <div className='flex '>

          {userData.user ? `Welcome back, ${userData.user.displayName}!` : '???'}<br/>

        </div>
      </div>
    </div>
  )
}

export default Welcomeheader