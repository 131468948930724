
function PageHeader(props: any) {

  const { title } = props
  window.console.log('header, ', title)
  return (
    <div className='w-screen sticky p-3 top-0 mainBackDarkGreen flex-row text-center '>
      <h1 className='text-white text-xl mb-0 poppinsM'>{title}</h1>
    </div>
  )
}

export default PageHeader