import React, { useEffect } from 'react';
import { useDataContext } from '../firebase/auth-provide';

function Profileheader() {
  const userData = useDataContext();
  const fellData = userData.userFellData

  let joinedDate;

  useEffect(() => {
    if (userData.user) {
      console.log('welcome!', userData.user);
      joinedDate = getJoinedDate()
    }
    if(fellData){

      window.console.log('DATA', fellData)
    }
  }, [userData]);

  const getUserImage = () => {
    if (userData.user && userData.user.photoURL) {
      return userData.user.photoURL;
    } else {
      return '/assets/images/mount-1.jpg';
    }
  };

  const getJoinedDate = () => {
    if(userData.user){

      window.console.log('joined', userData.user.metadata.createdAt)
      window.console.log(new Date(Number(userData.user.metadata.createdAt)).toLocaleDateString())
      return new Date(Number(userData.user.metadata.createdAt)).toLocaleDateString()
    } else {
      return ''
    }
  }
  
  const textTitleClass = () => {
    return 'text-m poppinsM mb-0 pb-0 text-gray-400'
  }
  const textDataClass = () => {
    return 'text-sm poppinsM mb-2 text-gray-600'
  }


  return (
    <div className='bg-white p-3 mt-3'>
      <div className='flex flex-row justify-evenly md:flex-col md:items-center'>
        <img
          className='w-1/3 h-100 rounded-full mb-2 md:mb-0 md:mr-4'
          src={getUserImage()}
          alt='Profile picture'
        />
        <div className='text-left md:text-left'>
          {userData.user && (
            <p className='poppinsB text-xl text-gray-600 mb-2'>{userData.user.displayName}</p>
          )}
          <p className={textTitleClass()}>Joined</p>
          <p className={textDataClass()}>{`${getJoinedDate()}`}</p>
           {fellData && (
            <>
            <p className={textTitleClass()}>Wainwrights</p>
            <p className={textDataClass()}>{Object.keys(fellData).length} / 214</p>
            </>
           )
           } 

        </div>
      </div>
    </div>
  );
}

export default Profileheader;
