// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, enableIndexedDbPersistence } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDygsrAvsa5I6ZFP0CcpZ1QTThzJVGAQIE",
  authDomain: "scramblr-d457d.firebaseapp.com",
  projectId: "scramblr-d457d",
  storageBucket: "scramblr-d457d.appspot.com",
  messagingSenderId: "122422547789",
  appId: "1:122422547789:web:a8b10887890c1487ac1304",
  measurementId: "G-PYE18QMJNR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

// enableIndexedDbPersistence(db);

// if emulators is set use those
if (process.env.REACT_APP_USE_EMULATORS === 'true') {
  connectFirestoreEmulator(db, 'localhost', 8001);
}