import React, { useCallback, useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { data } from "../data";
import { useParams } from "react-router-dom";
import L, { divIcon, LatLngExpression, map } from "leaflet";
import MyMarker from "./marker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonHiking } from "@fortawesome/free-solid-svg-icons";
import { renderToStaticMarkup } from "react-dom/server";
import { useDataContext } from '../firebase/auth-provide';

export function MyMap(props: any): JSX.Element {
	const { parentFunction, testdata } = props;

	const userData = useDataContext();
  	const fellData = userData.userFellData

	const { id, guided } = useParams();
	let selectedFell: {
		Name: string;
		Height: number;
		Latitude: number;
		Longitude: number;
		Book: string;
		ID: number;
	};

	const [coordinates, setCoordinates] = useState<{
		latitude: number;
		longitude: number;
	} | null>(null);

	const geolocation = useRef(navigator.geolocation);
	const permissions = useRef(navigator.permissions);

	useEffect(() => {
		if (permissions.current && permissions.current.query) {
			permissions.current.query({ name: "geolocation" }).then(function (result) {
				const permission = result.state;
				if (permission === "granted" || permission === "prompt") {
					geolocation.current.getCurrentPosition((position) => {
						setCoordinates({
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						});
					});
				}
			});
		}
	}, [permissions, geolocation, setCoordinates]);

	useEffect(() => {
		if (guided === "true" && id) {
			selectedFell = data[Number(id) - 1];
			handleMarkerClick(selectedFell, true);
		}
	}, [setCoordinates]);

	useEffect(() => {
		window.console.log('something updated felldata')
	}, [testdata]);

	const handleMarkerClick = useCallback(
		(data: any, open: boolean) => {
			window.console.log('GOT DATA!!!', data)
			parentFunction(open, data);
		},
		[parentFunction]
	);

	function userPosition() {
		const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon className="highlightText" icon={faPersonHiking} size="2xl" />);
		const customMarkerIcon = divIcon({
			html: iconMarkup,
		});
		if (coordinates !== null) {
			return (
				<>
					<Marker key='1' position={[coordinates.latitude, coordinates.longitude]} icon={customMarkerIcon}>
						<Popup>
							<span>Your location</span>
						</Popup>
					</Marker>
				</>
			);
		}
	}

	function getMapCenter(): LatLngExpression | undefined {
		// return if we have no coordinates the jsx already handles it.
		if (coordinates == null) {
			return;
		}

		// return the position in the url if its a directed view.
		if (guided === "true") {
			selectedFell = data[Number(id) - 1];
			return [selectedFell.Latitude - 0.02, selectedFell.Longitude];
		}

		// return the users coordinates if its not guided.
		return [coordinates.latitude, coordinates.longitude];
	}	

	function generateMarker(item: any, index: number){
		let climbed = false;
	  if(fellData){
			if (fellData.hasOwnProperty(item.ID)) {
				climbed = true;
			} else {
				climbed = false;
			}
	  }
		return (
				<MyMarker key={index} data={{ key:{index}, fellData: item, climbedCheck: climbed}} onClick={(open: boolean) => handleMarkerClick(item, open)}>
				</MyMarker>
		)
	}

	return (
		<>
			{coordinates ? (
				<MapContainer 
					closePopupOnClick={true}
					center={getMapCenter()} zoom={13} 
				>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
						{userPosition()}
						{data.map((item: any, index: number) => 
            				generateMarker(item, index) 
          				)}
				</MapContainer>
		
			) : (
				<div className="h-full relative flex align-center justify-center mainBackGreen text-white">
					<h1 className='poppinsM self-center'>one moment please.</h1>
				</div>
			)}
		</>
	);
}
