import L, { divIcon } from 'leaflet';
import { Marker, Popup } from 'react-leaflet'
import { Link } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountain } from '@fortawesome/free-solid-svg-icons';

const MyMarker = (props: any) => {
  const { data, onClick } = props
  const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon className="-translate-x-1/4 -translate-y-1/2" icon={faMountain} size={"2x"} color={getColour()} />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  function getColour(): string {
    return data.climbedCheck ? "#377574" : "red";
  }

  return (
    <>
      <Marker
        key={data.index}
        position={[data.fellData.Latitude, data.fellData.Longitude]} 
        {...props}
        icon={customMarkerIcon}
        eventHandlers={{
          click: (e) => {
            window.console.log(e)
            onClick(true)
          },
          
          popupclose: (e) => {
            onClick(false)
          }
        }}
      >
        <Popup>
          <span>{data.fellData.Name}</span><br/>
          <span>{data.fellData.Height}</span><br/>
          <span>Find out more <Link to={`/mountain/${data.fellData.Name}`}>here!</Link></span>
        </Popup>
      </Marker>
    </>
  )
}

export default MyMarker