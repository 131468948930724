import { faMap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';
import { useDataContext } from '../../firebase/auth-provide';

function ListChild(props: any) {
  const {id, data, fell, filter} = props
  const userData = useDataContext();
  const fellData = userData.userFellData
  // window.console.log(fellData[data.ID])

  function getcolour(id: number) {
    const nameData = fellData.hasOwnProperty(id);
    return nameData ? 'text-green-500' : 'text-red-500';
  }
  function showItem(id: number) {
    const nameData = fellData.hasOwnProperty(id);

    if(filter.showComplete) {
      return nameData ? 'block' : 'hidden'; 
    } else {
      return 'block'
    }
  }

  function getClimbs(){
    return fellData[data.ID] ? fellData[data.ID].climbs : '0'
  }

  function generateItem() {
    return  (

    // <li className={`bg-white text-left p-3 my-3 mx-2 rounded-xl shadow-md shadow-slate-300 flex justify-between  ${getcolour(data.ID)} ${showItem(data.ID)}`}>
    //   <div>
    //     <h3>{data.Name}</h3>
    //     <h5>Climbs: {getClimbs()}</h5>
        
    //   </div>
    //   <Link className='no-underline' role='button' to={`/map/${data.ID}/true`}>
    //     <div className='bg-slate-500 rounded-3xl text-white px-4 py-2 capitalize no-underline max-w-fit w-auto mx-auto '><FontAwesomeIcon icon={faMap} size={"1x"} /></div>
    //   </Link>
    // </li>

      <li className={`flex justify-between gap-x-6 py-2 ${showItem(data.ID)}`}>
        <div className="flex min-w-0 gap-x-4">
          {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
          <div className="min-w-0 flex-auto text-left">
            <p className="text-sm font-semibold leading-6 text-gray-900">{data.Name}</p>
            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{data.Height}m</p>
            <p className="mt-1 text-xs leading-5 text-gray-500">
              Climbs: {getClimbs()}
              
            </p>
          </div>
        </div>
        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
          {/* <p className="text-sm leading-6 text-gray-900">{data.ID}</p> */}
          
            
            {/* <Link className='no-underline' role='button' to={`/map/${data.ID}/true`}>
    <div className='bg-slate-500 rounded-3xl text-white px-4 py-2 capitalize no-underline max-w-fit w-auto mx-auto '><FontAwesomeIcon icon={faMap} size={"1x"} /></div>
    </Link> */}

          <Link className='no-underline' role='button' to={`/map/${data.ID}/true`}>
            <div className='secondaryColour text-white px-3 py-1 rounded-md capitalize no-underline align-bottom w-fit poppinsM'><FontAwesomeIcon icon={faMap} size={"1x"} /></div>
          </Link>

          <Link className='no-underline' role='button' to={``}>
            <div className='secondaryColour text-white px-3 py-1 rounded-md capitalize no-underline align-bottom w-fit poppinsM'><FontAwesomeIcon icon={faTrash} size={"1x"} /></div>
          </Link>
        </div>
      </li>

    )
    
  }
  return (
    <>
    {generateItem()}
    </>
    )
  }
  
export default ListChild