import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHiking, faMoon, faMountain, faRocket } from "@fortawesome/free-solid-svg-icons";


library.add(faMoon, faRocket, faMountain, faHiking);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);