import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { data } from './../data';
import PageHeader from '../components/page-header';

interface ClimbingData {
  Name: string;
  Height: number;
  Latitude: number;
  Longitude: number;
  Book: string;
  ID: number;
}

const Log: React.FC = () => {
  const { id, direct } = useParams();
  const [selectedName, setSelectedName] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const filteredData = data.filter((item) =>
    item.Name.toLowerCase().includes(filter.toLowerCase())
  );
  const selectedData = data.find((item) => item.Name === selectedName);

  const handleLogClimb = () => {
    // Handle logging the climb here (e.g., send data to a server)
    if (selectedData) {
      console.log('Logged climb for ID:', selectedData.ID);
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    setSelectedName(e.target.value);
  };

  useEffect(() => {
    if(direct === 'true'){
      window.console.log('direct me daddy')
      const idNumber = Number(id)-1
      window.console.log(idNumber, data[idNumber])
      setSelectedName(data[idNumber].Name)
      setFilter(data[idNumber].Name)
    } else {
      window.console.log('anything')
    }
  },[])

  useEffect(() => {
    window.console.log(filteredData.length)
    // if(filteredData.length === 1){
    //   const element = document.activeElement as any; 
    //   element.blur();
    //   (document.getElementById('nameFilter') as any).blur()
    // }
  }, [filteredData])

  return (
    <div>
      <PageHeader title={'Log Ascent'}></PageHeader>

      <div className="mt-4">
        <label htmlFor="nameFilter" className="block font-medium text-gray-700">
          Filter by Name:
        </label>
        <input
          type="text"
          id="nameFilter"
          name="filter"
          value={filter}
          onChange={handleFilterChange}
          list="nameList"
          className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        <datalist id="nameList">
          {filteredData.map((item) => (
            <option key={item.ID} value={item.Name} />
          ))}
        </datalist>
      </div>

      {selectedData && (
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Selected Data:</h2>
          <p>Name: {selectedData.Name}</p>
          <p>Height: {selectedData.Height}</p>
          <p>Latitude: {selectedData.Latitude}</p>
          <p>Longitude: {selectedData.Longitude}</p>
          <p>Book: {selectedData.Book}</p>
        </div>
      )}

      {selectedData && (
        <button
          onClick={handleLogClimb}
          className="mt-4 bg-indigo-500 text-white font-semibold p-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          Log Climb
        </button>
      )}
    </div>
  );
};

export default Log;
