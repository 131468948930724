import { User } from '@firebase/auth';
import { useEffect, useState } from 'react';
import AscendTile from '../components/ascend-tile';
import ProgressTile from '../components/progress-tile';
import { SuggestionContainer } from '../components/suggestion-container';
import Welcomeheader from '../components/welcome-header';
import { GoogleLogin } from '../firebase/google-login';
import { auth } from '../firebase/init-firebase';
import WelcomeIntro from '../components/welcome-intro';

const Home = () => {

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      if (user != null) {
        // User is signed in.
        setUser(user);
      } else {
        // User is not signed in.
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  
  return (
    <div className='overflow-auto relative h-9/10'>
      <WelcomeIntro/>

      <div>
        <Welcomeheader/>
        {user ? `` :<GoogleLogin/>}<br/>

      </div>

      <SuggestionContainer />
      <div className='m-3 flex space-x-3 align-middle justify-around'>
        
        <ProgressTile/>
        <AscendTile/>
        {/* <LogoutButton/> */}
      </div> 
    </div>
  );
};

export default Home;