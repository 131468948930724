import React from 'react';
import { useDataContext } from '../firebase/auth-provide';

const About = () => {

  const user = useDataContext();
  return (
    <div>
      <h1>About</h1>
      <p>This is an example of an About page.</p>
      <div>
      {user ? `Welcome, ${user.displayName}` : 'Please sign in'}
    </div>
    </div>
  );
};

export default About;