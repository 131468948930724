import { faBook, faMapMarkerAlt, faMountain, faPersonHiking, faRulerVertical, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDataContext } from '../firebase/auth-provide';
import { Link } from 'react-router-dom'


function MapPanel(props: any) {
  const userData = useDataContext();
  const fellData = userData.userFellData

  const { parentFunction, data } = props
  const userFellData = data.userFells

  const [panelData, setpanelData] = useState<any>(null);
  const [isActive, setisActive] = useState(false);


  useEffect(() => {
    setpanelData(data.fell)
    setisActive(data.open)
  }, [data])


  useEffect(() => {
    window.console.log('userfell data changed', userFellData)
    getclimbs()
  }, [fellData])

  function frameClass(): string {
    return isActive ? '-65%' : '-96%'
  }

  function getRotate(): string {
    return isActive ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  async function addClimbData() {
    parentFunction()
  }

  function getclimbs(): number {
    if (fellData == null || panelData == null) {
      return 0
    }
    const fell = fellData.hasOwnProperty(panelData.ID) ? fellData[panelData.ID].climbs : 0;
    return fell
  }

  return (
    <>
        <div style={{
        bottom: frameClass(),
        position: 'absolute',
        transition: '0.2s'
      }} className={`mainBackGreen z-[989] h-100 w-full mx-auto rounded-md shadow-lg p-2 text-white`}>
          <div className='cursor-pointer' onClick={() => setisActive(!isActive)}>
            <FontAwesomeIcon 
              style={{
                transform: getRotate()
                }}
              icon={faChevronUp} className="highlightText mb-8 transition-all" size="lg" /></div>
          <div className="flex items-center mb-4">

            <h1 className="text-xl font-bold">{panelData?.Name || 'Select a fell'}</h1>
          </div>
          {/* <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-400 mr-2" />
            <h5 className="text-sm">{panelData?.ID || 'Select a fell'}</h5>
          </div> */}

          <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faRulerVertical} className="text-gray-400 mr-2 w-6" />
            <p className="mb-0 text-sm">{panelData?.Height ? `${panelData.Height}m` : 'No height found'}</p>
          </div>

          <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faBook} className="text-gray-400 mr-2 w-6" />
            <p className="mb-0 text-sm">{panelData?.Book || 'No book found'}</p>
          </div>

          <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faPersonHiking} className="text-gray-400 mr-2 w-6" size="lg" />
              <p className="mb-0 text-sm">{getclimbs()} {getclimbs() > 1 || getclimbs() === 0 ? 'ascents' : 'ascent'}</p>
          </div>
          <div className="flex items-center m-2">
            <Button onClick={() => addClimbData()} className="text-sm mx-auto secondaryColour">Quick Ascent</Button>
             <Link className='no-underline mx-auto' role='button' to={`/log/${panelData ? panelData.ID+'/true' : 0+'/false'}`}>

            <Button className="text-sm secondaryColour">Custom Ascent</Button>
            </Link>

          </div>
        </div>
    </>



  );
}

export default MapPanel
