import { faAdd, faHouse, faMap, faTrophy, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useDataContext } from '../firebase/auth-provide';

export function NavbarComponent() {
  const user = useDataContext();

  function showProfile() {
    if(user) {
      return (
        <LinkContainer to="/profile"><Nav.Link><div className={`${iconStyles}`}><FontAwesomeIcon icon={faUserAlt} size={"1x"} /></div></Nav.Link></LinkContainer>
      )
    }
  }

  const iconStyles = 'text-gray-100 hover:text-yellow-500 rounded-full'

  return (
    <>
      <div className="w-full mx-auto mainBackDarkGreen flex items-center justify-around h-1/10 z-[999] fixed bottom-0">
        <LinkContainer to="/"><Nav.Link><div className={`${iconStyles}`}><FontAwesomeIcon icon={faHouse} size={"1x"} /></div></Nav.Link></LinkContainer>
        <LinkContainer to="/map/0/false"><Nav.Link><div className={`${iconStyles}`}><FontAwesomeIcon icon={faMap} size={"1x"} /></div></Nav.Link></LinkContainer>
        <LinkContainer to="/log/0/false"><Nav.Link><div className={`${iconStyles}`}><FontAwesomeIcon icon={faAdd} size={"1x"} /></div></Nav.Link></LinkContainer>
        <LinkContainer to="/achievements"><Nav.Link><div className={`${iconStyles}`}><FontAwesomeIcon icon={faTrophy} size={"1x"} /></div></Nav.Link></LinkContainer>
        {showProfile()}
      </div>
    </>
  );
}