import { Geo } from './components/geo';
import { Routes, Route, Link } from 'react-router-dom'
import About from './routes/about';
import Home from './routes/home';
import { Map } from './routes/map';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css'

import {NavbarComponent} from './components/nav';
import Mountain from './routes/mountain';
import { AuthProvider, useDataContext } from './firebase/auth-provide';
import Profile from './routes/profile';
import Achievements from './routes/achievements';
import AchievePopUp from './components/achievement/achieve-popup';
import Log from './routes/log';

function App() {

  return (
    <>
      <AuthProvider>
        <div className="App bg-white absolute h-screen w-screen">
          {/* <AchievePopUp/> */}
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/map/:id/:guided" element={<Map/>}/>
              <Route path="/mountain/:id" element={<Mountain/>}/>
              <Route path="/log/:id/:direct" element={<Log/>}/>
              <Route path="/profile" element={<Profile/>}/>
              <Route path="/achievements" element={<Achievements/>}/>
            </Routes>
          </div>
          <NavbarComponent/>
      </AuthProvider>
    </>
  );
}

export default App;
