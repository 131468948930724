import { faMountain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function AscendTile() {
  return (
    <div className='w-1/2 max-w-sm bg-white flex-col shadow-xl p-3 rounded-xl text-left flex align-top justify-end'>
      <FontAwesomeIcon className='text-left relative w-16 text-slate-800' icon={faMountain} size={"4x"} />
      <h1 className='text-slate-600 text-4xl font-bold'>2390m</h1>
      <h2 className='text-lg text-slate-500 font-weight-900 font-light'>Total Ascent</h2>
    
    </div>
  )
}

export default AscendTile