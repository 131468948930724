import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useContext } from 'react';
import { useDataContext, UserDataContext } from '../firebase/auth-provide';
import { db } from "../firebase/init-firebase";

// log a climb from a fell that the user hasnt climbed before.
export async function addFellItem(collectionReference: string, fellID: number) {

  const docRef = doc(db, `${collectionReference}`, 'wainwrights')
  window.console.log('trying to add fell item')
  const fells = {
    [fellID]: {
      climbs: 1
    }
  };
  
  await setDoc(docRef, { fells }, { merge: true });
  return fells

}