import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDataContext, UserDataContext } from '../firebase/auth-provide';

function ProgressTile() {

  const { user, setUser, userFellData, setUserFellData } = useContext(UserDataContext);

  const userData = useDataContext();
  const fellData = userData.userFellData

  useEffect(() => {
    if (userData.user) {
      window.console.log('welcome!', userData.user)
    }
  }, [userData]);


  function getBarClass() {
    return 'h-5 bg-green-500 rounded-full animate-pulse'
  }

  function getTotal() {
    return fellData ? `You've completed ${Object.keys(userData.userFellData).length} Wainwrights` : 'Loading'
  }

  function getWidth() {
    return fellData ? `${Object.keys(userFellData).length/214*100}%` : '0%'
  }

  return (
    <div className='w-1/2 max-w-sm bg-white text-center flex-col shadow-xl p-3 rounded-xl'>
    <h1 className='text-xl text-slate-800 font-weight-900 font-bold'>Progress</h1>
    <p className='text-slate-600 text-sm'>{getTotal()}</p>
    
    <div className='max-w-20 h-5 bg-slate-500 rounded-full'>
      <div style={{width: getWidth()}} className={getBarClass()}></div>
    </div>

    <Link className='no-underline' role='button' to={`/map/1/true`}>
      <div className='bg-slate-500 rounded-3xl text-white px-4 py-2 capitalize no-underline max-w-fit w-auto mt-4 mx-auto align-bottom'>view map</div>
    </Link>
  </div>
  )
}

export default ProgressTile