import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { auth, db } from './init-firebase';
import { User } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';

// Create the context
const UserDataContext = createContext<any>(null);

// Create a provider for components to consume and subscribe to changes
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userFellData, setUserFellData] = useState<any>(null);
  const [userFellCollection, setUserFellCollection] = useState<any>(null)

  // const providerValue = useMemo(() => ({
  //   user, setUser,
  //   userFellData, setUserFellData,
  // }), [user, userFellData, setUserFellData]);

  useEffect(() => {
    window.console.log('AUTH PROVIDER UPDATED!')
  }, [userFellData]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      setUserFellCollection("users/" + user.uid + "/completed")
    }
  }, [user]);

  useEffect(() => {
    if(user === null){
      return;
    }
    const getFirebaseData = async () => {
      window.console.log('REQUESTING FROM FIREBASE')
      const fellDataQuery = collection(db, `${userFellCollection}`)
      await getDocs(fellDataQuery).then((data) => {
        let userData: any
        let docData:any;
        data.docs.forEach(doc => {
          window.console.log('doc', doc.data())
          docData = doc.data()
          window.console.log(docData.fells)
          userData = docData.fells
        });
        window.console.log('GOT DATA BACK', userData)
        setUserFellData(userData)
      })
      .catch(error => {
        console.log(error);
      })
    }
    getFirebaseData()
  }, [userFellCollection])

  return (
    <UserDataContext.Provider value={{ user, setUser, userFellData, setUserFellData }}>
      {children}
    </UserDataContext.Provider>
  );
};



// Create a hook to consume the context
const useDataContext = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useDataContext, UserDataContext };